<template>
  <div class="page-wrapper">
    
    <header class="main-header always-sticky">
      <div class="left">
        <img src="@/assets/img/logo.svg" class="logo" alt="Logo Tremblant">
        <img src="@/assets/img/tagline-fr.svg" v-if="$route.meta.lang === 'fr'" class="tagline" alt="Tagline Tremblant">
        <img src="@/assets/img/tagline-en.svg" v-else-if="$route.meta.lang === 'en'" class="tagline" alt="Tagline Tremblant">
      </div>
      <div class="right">
        <div class="links">
          <a href="tel:18887381777">1-888-738-1777</a>
          <p>|</p>
          <router-link v-if="pageData && $route && $route.meta.lang === 'fr'" :to="{ name: 'pageEn', params: { pageData } }" class="lang">EN</router-link>
          <router-link v-else-if="pageData && $route && $route.meta.lang === 'en'" :to="{ name: 'page', params: { pageData } }" class="lang">FR</router-link>
        </div>
        <a href="#" class="cta red" @click.prevent="showlodgingform">{{ $t('site.reservez-long') }}</a>
      </div>
      <a href="tel:18887381777" class="phone-mobile"><img src="@/assets/img/phone4.svg" alt="Call Tremblant"></a>
      <div class="mobile-cta">
        <a href="#" class="cta red" @click.prevent="showlodgingform">{{ $t('site.reservez-long') }}</a>
      </div>
    </header>

    <section class="terms-wrap">

      <div v-if="$route.meta.lang === 'fr'">

        <button @click.prevent="goback" class="cta prune">Retour</button><br><br>

        <h1 class="big-title">Modalités<br>hébergement</h1>

        <h2 class="small-title">Validité</h2>
        <ul>
          <li class="regular-text">Le rabais de 10 % s’applique sur les séjours de 2 nuits du 1 er décembre 2022 au 30 avril 2023</li>
          <li class="regular-text">Le rabais de 15 % s’applique sur les séjours de 3 nuits ou plus du 1 er  décembre 2022 au 30 avril 2023.</li>
          <li class="regular-text">Offre valable tous les jours du 1 er  décembre 2022 au 30 avril 2023, sauf les 9 et 10 décembre 2022, du 26 décembre 2022 au 6 janvier 2023, du 17 février au 4 mars 2023 et du 10 au 18 mars 2023 où les partenaires hôteliers participants pourraient ne pas offrir de rabais, ou offrir un rabais inférieur à ceux stipulés dans cette offre.</li>
          <li class="regular-text">Période prévue pour la saison de ski 2022-2023 : 24 novembre 2022 au 16 avril 2023. Modifiable sans préavis.</li>
          <li class="regular-text">Offre disponible chez les partenaires hôteliers participants.</li>
          <li class="regular-text">Sous réserve de disponibilité.</li>
        </ul>
        
        <h2 class="small-title">Achat et réservation</h2>
        <ul>
          <li class="regular-text">L’offre s’applique sur toute réservation effectuée entre le 30 novembre 2022 et le 29 avril 2023 pour un séjour de 2 nuits ou plus entre le 1 er décembre 2022 et le 30 avril 2023.</li>
          <li class="regular-text">Le séjour doit être réservé au moins 24 heures d’avance.</li>
        </ul>

        <h2 class="small-title">Remboursement</h2>
        <ul>
          <li class="regular-text">Les <a href="https://www.tremblant.ca/a-propos/politiques?sc_lang=fr-CA" target="_blank">politiques d’acompte régulières</a> des partenaires hôteliers participants s’appliquent à cette offre.</li>
          <li class="regular-text">Les politiques varient d’un partenaire à l’autre.  Sujet à changement sans préavis.</li>
        </ul>

        <h2 class="small-title">Restrictions</h2>
        <ul>
          <li class="regular-text">Les 9 et 10 décembre 2022, du 26 décembre 2022 au 6 janvier 2023, du 17 février au 4 mars 2023 et du 10 au 18 mars 2023, les partenaires hôteliers participants pourraient ne pas offrir de rabais, ou offrir un rabais inférieur à ceux stipulés dans cette offre.</li>
          <li class="regular-text">Cette offre ne peut être jumelée avec d’autres offres ou promotions sur l’hébergement.</li>
          <li class="regular-text">Si le séjour chevauche la période de début ou de fin de validité de l’offre, le rabais sera appliqué sur les nuits à l’intérieur de la période de validité selon le rabais correspondant au nombre total de nuits du séjour.</li>
          <li class="regular-text">Ces rabais et dates sont modifiables sans préavis.</li>
        </ul>

        <!-- <div class="best-price">
          <img src="@/assets/img/best-price-fr.svg" alt="Meilleur prix garanti Tremblant">
          <p class="regular-text">Si vous trouvez sur Internet une réservation en hébergement à un prix plus avantageux que celui que vous avez réservé auprès de notre service, nous honorerons ce prix dans les 48 heures suivant votre réservation. Vous pouvez donc réserver en toute confiance dès maintenant auprès de notre centrale de réservations ou directement à même notre système de réservation en ligne avec l'assurance de toujours obtenir la garantie du meilleur tarif disponible.</p>
        </div> -->
      </div>

      <div v-else-if="$route.meta.lang === 'en'">

        <button @click.prevent="goback" class="cta prune">Back</button><br><br>

        <h1 class="big-title">Lodging<br>Terms & Conditions</h1>

        <h2 class="small-title">Validity</h2>
        <ul>
          <li class="regular-text">10% discount applies to 2-night stays from December 1, 2022, to April 30, 2023.</li>
          <li class="regular-text">15% discount applies to 3-night stays or more from December 1, 2022, to April 30, 2023.</li>
          <li class="regular-text">Valid every day from December 1, 2022, to April 30, 2023, except on December 9 and 10, from December 26, 2022, to January 6, 2023, from February 17 to March 4, 2023, and from March 10 to 18, 2023, when hotels may offer lower discounts than the ones stipulated in this offer, or no discount at all.</li>
          <li class="regular-text">Offer available at participating lodging partners.</li>
          <li class="regular-text">Subject to availability.</li>
          <li class="regular-text">Planned opening dates for the 2022–2023 ski season: from November 24, 2022, to April 16, 2023. Subject to change without notice.</li>
        </ul>
        
        <h2 class="small-title">Purchase and Booking</h2>
        <ul>
          <li class="regular-text">Offer applies to bookings made between November 30, 2022 and April 29, 2023 on 2-night stays or more from December 1, 2022, to April 30, 2023</li>
          <li class="regular-text">Bookings must be made at least 24 hours prior to arrival date.</li>
        </ul>

        <h2 class="small-title">Refund</h2>
        <ul>
          <li class="regular-text">Participant hotels' <a href="https://www.tremblant.ca/about-us/policies?sc_lang=en" target="_blank">regular deposit and cancellations policies</a> apply to this offer. </li>
          <li class="regular-text">Policies vary from partner to partner. Subject to change without notice.</li>
        </ul>

        <h2 class="small-title">Restrictions</h2>
        <ul>
          <li class="regular-text">On December 9 and 10, from December 26, 2022, to January 6, 2023, from February 17 to March 4, 2023, and from March 10 to 18, 2023, hotel partners may offer lower discounts than the ones stipulated in this offer, or no discount at all.</li>
          <li class="regular-text">This offer cannot be combined with any other offer or promotion on lodging.</li>
          <li class="regular-text">If stay period starts or ends outside of the validity period, discount will be applied on nights included within the validity period only and its value will vary according to the number of nights included in the entire stay.</li>
          <li class="regular-text">These discounts and dates are subject to change without notice.</li>
        </ul>

        <!-- <div class="best-price">
          <img src="@/assets/img/best-price-en.svg" alt="Meilleur prix garanti Tremblant">
          <p class="regular-text">You can reserve any of our lodging properties by visiting <a href="http://tremblant.ca/" target="_blank">tremblant.ca</a> or calling our central reservations office. Part of our mission is also making sure that you get the best price PERIOD. What this means is if you find a better price for your lodging anywhere on the Internet than the one you already reserved with us, we will match that price.</p>
        </div> -->
      </div>
      
    </section>

    <footer-bar></footer-bar>

    <!--Logo Partenaire-->
    <div class="logo-partenaire">
      <a v-if="$route.meta.lang === 'fr'" href="https://www.laurentides.com/fr" target="_blank"
        ><img src="@/assets/img/logo-tl-fr.png" alt="Logo Tourisme Laurentides"
      /></a>
      <a v-else-if="$route.meta.lang === 'en'" href="https://www.laurentides.com/en" target="_blank"
        ><img src="@/assets/img/logo-tl-en.png" alt="Logo Tourisme Laurentides"
      /></a>
    </div>

    <div class="overlay-form-loding" :class="{ show: formlodging }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidelodgingform">
      <div class="form-wrap">
        <h3 class="medium-title">{{ $t('site.reservez-long') }}</h3>

        <form
          action="https://reservations.tremblant.ca/Ecomm/Shop/Lodging/2624850/fr-CA/?_ga=2.247307652.1090954148.1629141557-1179124804.1583853558&_gl=1*t5gc5p*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIyOTEzOC4xNC4wLjE2MjkyMjkxMzguMA"
          class="lodging-form"
          target="_blank"
        >
          <div class="input">
            <p class="regular-text">{{ $t('site.arrivee') }}</p>
            <date-picker
              name="arrivaldate"
              v-model="time1"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'arrivaldate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.depart') }}</p>
            <date-picker
              name="departuredate"
              v-model="time2"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'departuredate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.adulte') }}</p>
            <input
              name="adultcount"
              type="number"
              value="2"
              min="1"
              required
            >
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.enfant') }}</p>
            <input
              name="childCount"
              type="number"
              value="0"
              required
            >
          </div>
          <input type="submit" class="cta submit blanc-rouge" :value="$t('site.reservez-long')">
        </form>
      </div>
      <div class="opacity" @click.prevent="hidelodgingform"></div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

import * as LottiePlayer from '@lottiefiles/lottie-player'

import GLightbox from 'glightbox'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import trans from '@/translations/index'

import NewsLetter from '@/components/NewsLetter'
import FooterBar from '@/components/FooterBar'

export default {
  components: { NewsLetter, FooterBar, DatePicker },
  head() {
    return {
      title: {
        inner: "Éclaté de haut en bas"
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `Tremblant - Offres` },
        {
          name: 'description',
          content: `Profitez des meilleures offres et prix de l'année.`,
          id: 'desc'
        },
        // Twitter OG
        { name: 'twitter:title', content: `Tremblant - Offres` },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: `Profitez des meilleures offres et prix de l'année.`
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `Tremblant - Offres` },
        { property: 'og:site_name', content: `Tremblant - Offres` },
        {
          property: 'og:description',
          content: `Profitez des meilleures offres et prix de l'année.`
        },
        // Og Image
        {
          p: 'og:image',
          c:
            ''
        },
        {
          name: 'twitter:image',
          content: ''
        }
      ]
    }
  },
  props: {
    pageData: {
      type: Object,
      required: true,
      default: null
    },
  },
  data() {
    return {
      toggle: true,
      formlodging: false,
      sidepanel: false,
      lottieInstance: null,
      lightboxelement: null,
      datepickerLang: null,
      time1: new Date(),
      time2: null
    }
  },
  computed: mapState('app', ['appTitle']),
  methods: {
    goback() {
        return this.$router.go(-1)
    },
    table1() {
        this.toggle = true
    },
    table2() {
        this.toggle = false
    },
    showlodgingform() {
        if(this.formlodging) {
          this.formlodging = false
        } else {
          this.formlodging = true
        }
    },
    hidelodgingform() {
      this.formlodging = false
    },
    showsidepanel() {
        if(this.sidepanel) {
          this.sidepanel = false
        } else {
          this.sidepanel = true
        }
    },
    hidesidepanel() {
      this.sidepanel = false
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    snowscroll() {
      const st = document.documentElement.scrollTop

      const element = this.$el.querySelector('.snow-background')
      const elementStyle = element.style

      element.style.top = `${ -150 + st /2}px`
    } 
  },
  mounted () {
    this.lottieInstance = LottiePlayer

    this.lightboxelement = GLightbox
    GLightbox()

    const dt = new Date()
    dt.setDate(dt.getDate() + 1)
    this.time2 = dt

    this.datepickerLang = trans.fr.datepicker
    if (this.$route.meta.lang === 'en') {
      this.datepickerLang = trans.en.datepicker
    }
  },
  created() {
      window.addEventListener('scroll', () => {
        this.snowscroll()
      })
    },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
